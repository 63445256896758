import React, { useState, useEffect } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  IconButton,
  Grid,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useTranslation } from "react-i18next";
import { SVGStyledComponent } from "../utilities/Constants";
import WIPIcon from "../data/images/wip.svg";
import { POP_UP } from "../utilities/constantsLocale";

const PopupAlert = () => {
  const [isVisible, setIsVisible] = useState(false);
  const { t, i18n } = useTranslation();

  useEffect(() => {
    // Always start the timer when component mounts
    const timer = setTimeout(() => {
      setIsVisible(true);
    }, 5000); // 5000 milliseconds = 5 seconds

    // Cleanup timer on unmount
    return () => clearTimeout(timer);
  }, []); // Empty dependency array means this runs once on mount

  const handleClose = () => {
    setIsVisible(false);
  };

  return (
    <Dialog
      open={isVisible}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <Grid container style={{ padding: "15px" }} spacing={2}>
        <Grid
          item
          xs={12}
          sm={12}
          md={6}
          lg={6}
          justifyContent="center"
          style={{ textAlign: "center", padding: "10px" }}
        >
          {SVGStyledComponent(WIPIcon, "Work in progress")}
        </Grid>
        <Grid
          item
          xs={12}
          sm={12}
          md={6}
          lg={6}
          justifyContent="center"
          style={{ textAlign: "center", padding: "10px" }}
        >
          <DialogTitle id="alert-dialog-title" sx={{ pr: 6 }}>
            {t(`${POP_UP}title`)}
            <IconButton
              aria-label="close"
              onClick={handleClose}
              sx={{
                position: "absolute",
                right: 8,
                top: 8,
              }}
            >
              <CloseIcon />
            </IconButton>
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              {t(`${POP_UP}message`)}
            </DialogContentText>
          </DialogContent>
        </Grid>
      </Grid>
    </Dialog>
  );
};

export default PopupAlert;
