import ImportContacts from "@mui/icons-material/ImportContacts";
import { Box, Divider, Grid } from "@mui/material";
import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import pageContent, { BackButton } from "../components/PageInformation";
import { ReactComponent as LiteratureAnimate } from "../data/images/literature-animate.svg";
import {
  LARGE_WINDOW_BREAK_POINT,
  MEDIUM_WINDOW_BREAK_POINT,
  PRIMARY_COLOR,
} from "../utilities/Constants";

import { useTranslation } from "react-i18next";
import { useWindowDimensions } from "../utilities/useWindowDimensions";
import "./MainPage.css";

import { useKeenSlider } from "keen-slider/react";
import { CustomDiv } from "../components/CustomDiv";
import { MAIN_PAGE_FEATURED_BOOKS } from "../utilities/constantsLocale";
import {
  MainPageFeaturedArtists,
  MainPageFeaturedAuthor,
  MainPageFeaturedBooks,
  MainPageFeaturedFacts,
  MainPageFeaturedMusician,
  MainPageSummaryBar,
} from "./landingPage";
import PopupAlert from "../components/PopUp";

export const determineGradienDirection = (windowSize) => {
  if (windowSize > LARGE_WINDOW_BREAK_POINT) return "linear-down";
  if (LARGE_WINDOW_BREAK_POINT > windowSize > MEDIUM_WINDOW_BREAK_POINT)
    return "linear-down";
  if (MEDIUM_WINDOW_BREAK_POINT > windowSize > LARGE_WINDOW_BREAK_POINT)
    return "linear-up";
  return "linear-up";
};

export const MainPage = () => {
  const navigate = useNavigate();
  const windowSize = useWindowDimensions();
  const { t, i18n } = useTranslation();

  const [currentLanguage, setCurrentLanguage] = React.useState("enUs");
  const [sliderRef] = useKeenSlider(
    {
      loop: true,
      vertical: true,
    },
    [
      (slider) => {
        let timeout;
        let mouseOver = false;
        function clearNextTimeout() {
          clearTimeout(timeout);
        }
        function nextTimeout() {
          clearTimeout(timeout);
          if (mouseOver) return;
          timeout = setTimeout(() => {
            slider.next();
          }, 2000);
        }
        slider.on("created", () => {
          slider.container.addEventListener("mouseover", () => {
            mouseOver = true;
            clearNextTimeout();
          });
          slider.container.addEventListener("mouseout", () => {
            mouseOver = false;
            nextTimeout();
          });
          nextTimeout();
        });
        slider.on("dragStarted", clearNextTimeout);
        slider.on("animationEnded", nextTimeout);
        slider.on("updated", nextTimeout);
      },
    ]
  );

  const animation = { duration: 10000, easing: (t) => t };

  useEffect(() => {
    setCurrentLanguage(i18n.language);
  }, [i18n.language]);

  // style={{ backgroundColor: "#dfdbce" }}
  return (
    <div>
      {/* </><WorkInProgress link={"/book"} linkText={"Кітапхана"} /> */}
      <PopupAlert/>
      <Grid
        container
        // spacing={0}
        align="center"
        justify="center"
        // direction="column"
      >
        <Grid container align="center" justify="center">
          <Grid
            item
            align="center"
            alignItems="center"
            xs={12}
            sm={12}
            md={6}
            xl={6}
          >
            <CustomDiv className="linear-down">
              <LiteratureAnimate
                style={{
                  width: "100%",
                  height: "auto",
                  viewBox: "0 0 150 150",
                }}
              />
            </CustomDiv>
          </Grid>
          <Grid
            item
            // align="center"
            // alignItems="center"
            // className={determineGradienDirection(windowSize.width)}
            xs={12}
            sm={12}
            md={6}
            xl={6}
            style={{ display: "flex" }}
          >
            <CustomDiv
              className={determineGradienDirection(windowSize.width)}
              style={{
                padding: "40px",
              }}
            >
              <h1>
                <Grid container>
                  {" "}
                  <Grid
                    item
                    xs={5}
                    sm={5}
                    md={5}
                    xl={5}
                    textAlign="end"
                    sx={{ color: "#1C3D79" }}
                  >
                    {" "}
                    Qaz
                  </Grid>
                  <Grid item xs={7} sm={7} md={7} xl={7}>
                    {" "}
                    {
                      <div
                        ref={sliderRef}
                        className="keen-slider"
                        style={{ height: 50, m: 0 }}
                      >
                        <div className="main-page keen-slider__slide number-slide1 ">
                          {currentLanguage === "enUs"
                            ? "Convert"
                            : "Түрлендіру"}
                        </div>
                        <div className="main-page keen-slider__slide number-slide2">
                          {currentLanguage === "enUs" ? "Book" : "Кітап"}
                        </div>
                        <div className="main-page keen-slider__slide number-slide3">
                          {currentLanguage === "enUs" ? "Research" : "Зерттеу"}
                        </div>
                        <div className="main-page keen-slider__slide number-slide3">
                          Bilim
                        </div>
                      </div>
                    }
                  </Grid>
                </Grid>
              </h1>
              <>
                {pageContent(
                  "Digitizing and preserving Qazaqstani cultural and linguistic heritage",
                  "qaz-En"
                )}
              </>
              <Divider
                variant="middle"
                sx={{
                  borderBottomWidth: 1,
                  backgroundColor: PRIMARY_COLOR,
                  boxShadow: "0 0 10px",
                  my: 1,
                }}
              />
              <p>
                {pageContent(
                  "Қазақстанның тілдік және мәдени мұрасын цифрландыру және сақтау",
                  "qaz-Cy"
                )}
              </p>
            </CustomDiv>
          </Grid>
        </Grid>

        <Grid
          item
          alignItems="center"
          align="center"
          // style={{ display: "flex" }}
          xs={12}
          sm={12}
          md={12}
          xl={12}
          style={{ backgroundColor: "#dfdbce" }}
        >
          <MainPageSummaryBar
            windowSize={windowSize}
            // localeObject={t("home.landingPage.mainPageSummaryBar")}
          />
        </Grid>
        <Grid
          container
          // alignItems="center"
          // align="center"
        >
          <Grid
            item
            alignItems="center"
            justifyContent="center"
            xs={12}
            sm={6}
            md={6}
            xl={6}
            style={{ display: "flex" }}
          >
            <div align="center" style={{ padding: "30px" }}>
              <h1>{t(`${MAIN_PAGE_FEATURED_BOOKS}featured-books-header`)}</h1>
              {t(`${MAIN_PAGE_FEATURED_BOOKS}featured-books-content`)}
              <p>
                <BackButton
                  navigate={navigate}
                  link={"/book"}
                  linkText={t(`${MAIN_PAGE_FEATURED_BOOKS}library-button`)}
                  icon={<ImportContacts style={{ marginBottom: "4px" }} />}
                />
              </p>
            </div>
          </Grid>
          <Grid
            item
            alignItems="center"
            align="center"
            xs={12}
            sm={6}
            md={4}
            xl={4}
            p={2}
          >
            <MainPageFeaturedBooks navigate={navigate} />
          </Grid>
          <Grid item xs={0} sm={6} md={2} xl={2}></Grid>
        </Grid>

        <Grid container>
          <MainPageFeaturedArtists currentLanguage={currentLanguage} />
        </Grid>

        <Grid
          item
          alignItems="center"
          align="center"
          // style={{ display: "flex" }}
          xs={12}
          sm={12}
          md={6}
          xl={6}
        ></Grid>

        <Grid container>
          <MainPageFeaturedMusician currentLanguage={currentLanguage} />
        </Grid>

        <Grid
          container
          // alignItems="center"
          // align="center"
          style={{ backgroundColor: "#dfdbce" }}
        >
          <Grid
            item
            alignItems="center"
            align="center"
            xs={0}
            sm={0}
            md={2}
            xl={2}
          ></Grid>
          <Grid
            item
            alignItems="center"
            align="center"
            xs={12}
            sm={12}
            md={8}
            xl={8}
          >
            <Box
              sx={{
                p: 2,
                m: 2,
              }}
            >
              <MainPageFeaturedAuthor currentLanguage={currentLanguage} />
            </Box>
          </Grid>
          <Grid
            item
            alignItems="center"
            align="center"
            xs={0}
            sm={0}
            md={2}
            xl={2}
          ></Grid>
          <Grid
            item
            // alignItems="center"
            align="center"
            xs={12}
            sm={12}
            md={6}
            xl={6}
          >
            <Box
              sx={{
                borderRadius: "5px",
                p: 2,
                m: 2,
                backgroundColor: "#dfdbce",
                // color: "white",
              }}
            >
              <MainPageFeaturedFacts />
            </Box>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};
