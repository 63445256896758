export const HOME = `home.`;
export const LANDING_PAGE = `${HOME}landingPage.`;
export const MAIN_PAGE_MENU_BAR = `${HOME}mainPageMenuBar.`;
export const MAIN_PAGE_BOOK_CARD_DIALOG = `${HOME}mainPageBookCardDialog.`;

export const MAIN_PAGE_SUMMARY_BAR = `${LANDING_PAGE}mainPageSummaryBar.`;
export const MAIN_PAGE_FEATURED_BOOKS = `${LANDING_PAGE}mainPageFeaturedBooks.`;
export const MAIN_PAGE_FEATURED_PAINTER = `${LANDING_PAGE}mainPageFeaturedPainter.`;
export const MAIN_PAGE_FEATURED_MUSICIAN = `${LANDING_PAGE}mainPageFeaturedMusician.`;
export const MAIN_PAGE_FEATURED_AUTHOR = `${LANDING_PAGE}mainPageFeaturedAuthor.`;
export const MAIN_PAGE_FEATURED_FACTS = `${LANDING_PAGE}mainPageFeaturedFacts.`;

export const WORK_IN_PROGRESS_PAGE = `workInProgressPage.`;

export const CONVERT_PAGE = `convert.`;

export const COMPONENTS = `components.`;
export const COMPONENTS_SPEED_DIAL_UTILITIES = `${COMPONENTS}speedDialUtilities.`;

export const READER_PAGE = `reader.`;
export const READER_PAGE_BREADCRUMBS = `${READER_PAGE}breadcrumbs.`;

export const POP_UP = "popup."
